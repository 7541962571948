<basic-uploader uploaderId="image-list-uploader"
  [uploadManager]="uploadManager"
  [validType]="fileType"
  [fileList]="fileUploadList"
  [shown]="false">
</basic-uploader>

<form #promptForm="ngForm" class="p-4 flex flex-col gap-3">
  @if (!results && !loading) {
    <h4>What are you looking for?</h4>
    <div>
      <textarea class="w-100 form-control" [(ngModel)]="promptText" name="prompt" placeholder="Describe the presentation you'd like to create..."></textarea>
    </div>
    <button class="btn btn-primary btn-block" (click)="apply()">Submit</button>
  }
  @else {
    <h4>
      @if (!results) {
        Generating Templates...
      }
      @else {
        Generated Templates
      }
    </h4>
    <template-suggestions [presentations]="presentationIds" (selectTemplate)="selectTemplate()"></template-suggestions>
  }

</form>
